import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../hooks/hooks';
import { FileUploadStatus, isErrorStatus } from './FileUpload';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import Button from '../button/Button';

const FilenameTextWidget = ({ widgetWidth, text }: { widgetWidth: number, text: string }) => {
  const half = widgetWidth / 2;
  return (
    <div className='flex flex-row'>
      <div
        className='truncate'
        style={{ width: `${half}px` }}
      >
        {text}
      </div>
      <div
        className='overflow-hidden whitespace-nowrap text-clip'
        style={{ width: `${half}px`, direction: 'rtl' }}
      >
        {text}
      </div>
    </div>
  );
};

const FilenameWidget = ({
  file,
  filename,
  fileUrl,
  fileStatus = 'ready',
  visible,
  onClick,
} : {
  file?: File,
  filename?: string,
  fileUrl?: string,
  fileStatus?: FileUploadStatus,
  visible: boolean,
  onClick?: () => void,
}) => {
  const dispatch = useAppDispatch();
  const filenameRef = useRef({} as any);
  const [hasScroll, setHasScroll] = useState(false);
  const [widgetWidth, setWidgetWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const windowResizeTimerRef = useRef(0);
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    }
  });

  const onWindowResize = () => {
    if (visible) {
      if (windowResizeTimerRef.current) {
        window.clearTimeout(windowResizeTimerRef.current);
      }
      windowResizeTimerRef.current = window.setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 100);
    }
  };

  const errorMessages: Record<string, string> = {
    'error': 'Something went wrong. Try again.',
    'unsupported': 'Unsupported file type. Please select a different file.',
    'unsupported-size': 'File size exceeds 50MB limit. Please select a different file.',
    'rejected': 'File did not pass security check. Please select a different file.'
  };

  useEffect(() => {
    const element = filenameRef.current as HTMLElement;
    if (visible && element && element.scrollWidth > element.clientWidth) {
      setHasScroll(true);
      setWidgetWidth(element.clientWidth);
    } else {
      setHasScroll(false);
    }
  }, [visible, windowWidth]);

  if (isErrorStatus(fileStatus)) {
    return (
      <div className='flex flex-col overflow-hidden'>
        <span className='font-bold text-inactive-75 truncate OneLinkNoTx'>{file?.name || filename}</span>
        <span className='text-xs text-criticalText'>
          {errorMessages[fileStatus]}
        </span>
      </div>
    );
  }

  const handleFilenameClick = async (url: string, filename: string) => {
    onClick?.();
    if (mobileAppDocumentEvent && url) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', url, filename);
      dispatch(updateGlobalLoading(false));
    }
  };

  const text = file?.name || filename || 'file';
  const url = file ? URL.createObjectURL(file) : fileUrl;
  const downloadUrl = mobileAppDocumentEvent ? undefined : url;

  return (
    <div className='flex flex-col overflow-hidden'>
      {/* this is an invisible ruler */}
      <Button
        anchorRef={filenameRef}
        className='truncate h-0 OneLinkNoTx'
        buttonType='tertiary'
        text={text}
      />
      {hasScroll ?
        <Button
          className='OneLinkNoTx text-sm lg:text-base'
          buttonType='tertiary'
          href={downloadUrl}
          target='_blank'
          onClick={() => url && handleFilenameClick(url, text)}
        >
          <FilenameTextWidget text={text} widgetWidth={widgetWidth} />
        </Button>
        :
        <Button
          className='truncate OneLinkNoTx text-sm lg:text-base'
          buttonType='tertiary'
          text={text}
          href={downloadUrl}
          target='_blank'
          onClick={() => url && handleFilenameClick(url, text)}
        />
      }
    </div>
  );
};

export default FilenameWidget;
