import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectors } from '../../../features/tasks';
import type { RootState } from '../../../store/types';
import Accordion from '../../ui/accordion/Accordion';
import TaskTitle from './TaskTitle';
import { ConsentTaskTitle, ConsentTaskBody, CONSENT_TYPES } from '../consent/Consent';
import { PaymentTaskBody, PAYMENT_TYPES } from '../payment/Payment';
import {
  LanguagePreferenceTaskBody,
  LanguagePreferenceTaskTitle,
  LANGUAGE_PREFERENCE_TYPES,
} from '../languagePreference/LanguagePreference';
import {
  TaskBodyTemplateProps,
  DefaultTaskBodyProps,
  TaskCollapsibleProps,
  TaskProps,
  TaskTemplateProps,
  TaskTitleCtaProps,
  TaskTitleTemplateProps,
} from './ITask';
import './Task.scss';
import TaskUpload from './TaskUpload';
import { useEffect, useState } from 'react';
import { useTaskText } from '../../../hooks/useTaskText';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import LoxTaskBody, { LOX_TYPES } from '../lox/LoxTaskBody';
import DocutechTaskBody, { DOCUTECH_TYPES } from '../docutech/Docutech';
import VoaTaskBody, { VOA_TYPES } from '../voa/Voa';
import BorrowerAffirmations, { BORROWER_AFFIRMATION_TYPES } from '../affirmations/BorrowerAffirmationsTaskBody';
import { log } from '../../../utils/logger';
import { QuoteCTA, iSize } from '../../loan/insuranceQuote/InsuranceQuote';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import VoieTaskBody from '../voie/Voie';
import VoieReverifyTaskBody from '../voie/VoieReverify';
import { AGENT_TYPES, AgentConsentTaskBody, AgentConsentTaskTitle } from '../agent/AgentConsentTask';
import VoaiTaskBody, { VOAI_TYPES } from '../voa/Voai';
import GrVoaTaskBody from '../voa/GrVoa';
import AssetsReverifyTaskBody, { ASSETS_REVERIFY_TYPES } from '../voa/AssetsReverify';

const isType = (type: string, types: Readonly<string[]>): boolean => types.includes(type);

const TaskTitleTemplate = ({ task, loanGuid }: TaskTitleTemplateProps) => {
  const taskType = task['task-type'];
  const title = useTaskText(task, 'title', loanGuid);

  let tasktitle;
  if (!title) {
    tasktitle = null;
  } else if (isType(taskType, CONSENT_TYPES)) {
    tasktitle = <ConsentTaskTitle task={task} taskTitle={title} />;
  } else if (isType(taskType, LANGUAGE_PREFERENCE_TYPES)) {
    tasktitle = <LanguagePreferenceTaskTitle task={task} taskTitle={title} />;
  } else if (isType(taskType, AGENT_TYPES)) {
    tasktitle = <AgentConsentTaskTitle task={task} taskTitle={title} />;
  } else if (title) {
    tasktitle = <TaskTitle title={title} className='OneLinkNoTx' />;
  }

  return (
    <div className='task-title' data-qa='task-title'>
      {tasktitle}
    </div>
  );
};

export const DefaultTaskBodyDescription = ({ taskDescription }: { taskDescription?: string }) => {
  return (
    <div className='mb-6'>
      <p className='task-description OneLinkNoTx' data-qa='task-description'>
        {taskDescription}
      </p>
    </div>
  );
};

export const AdditionalTaskDetails = ({ additionalDetails }: { additionalDetails: string }) => {
  return (
    <div className='mb-4 mt-8'>
      <p className='task-title'>Additional details</p>
      <p className='task-description OneLinkNoTx' data-qa='task-additional-details'>
        {additionalDetails}
      </p>
    </div>
  );
};

const DefaultTaskBody = ({ task, loanGuid }: DefaultTaskBodyProps) => {
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  return (
    <>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
    </>
  );
};

const InsuranceTaskCTA = ({ loanGuid }: { loanGuid: string }) => {
  const ctaSizes: { size: iSize; classes: string }[] = [
    { size: 'small', classes: 'block sm:hidden' },
    { size: 'medium', classes: 'hidden sm:block xl:hidden' },
    { size: 'large', classes: 'hidden xl:block' },
  ];
  return (
    <>
      {ctaSizes.map(({ classes, size }) => (
        <div className={classes} key={size}>
          <QuoteCTA loanGuid={loanGuid} size={size} className='mx-auto' source='Task' location='task' />
        </div>
      ))}
    </>
  );
};

const UploadTaskBody = ({ task, loanGuid, visible = false, setAccordionActive }: TaskBodyTemplateProps) => {
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const showInsuranceQuote = useFeatureEnabled('insurance-online-marketplace') && task['task-key'] === 'insurance_quote';
  return (
    <>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      {showInsuranceQuote && <InsuranceTaskCTA loanGuid={loanGuid} />}
      <TaskUpload loanGuid={loanGuid} task={task} visible={visible} setAccordionActive={setAccordionActive} />
    </>
  );
};

const TaskBodyTemplate = ({ task, loanGuid, visible, setAccordionActive }: TaskBodyTemplateProps) => {
  const {
    'task-type': taskType,
    'task-key': taskKey,
    'task-category': taskCategory,
    'task-action-type': taskActionType,
    'task-meta': taskMeta,
    'task-id': taskId,
    source,
  } = task;

  switch (true) {
    case isType(taskType, CONSENT_TYPES):
      return <ConsentTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, AGENT_TYPES):
      return <AgentConsentTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, PAYMENT_TYPES):
      return <PaymentTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, LANGUAGE_PREFERENCE_TYPES):
      return <LanguagePreferenceTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, LOX_TYPES):
      return <LoxTaskBody task={task} loanGuid={loanGuid} setAccordionActive={setAccordionActive} />;
    case isType(taskType, DOCUTECH_TYPES):
      return <DocutechTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, ['credentialed_voie']):
      return <VoieTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, ['credentialed_voie_reverify']):
      return <VoieReverifyTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, VOAI_TYPES) || taskMeta?.['task-display-type'] === 'gr_assets_voai':
      return <VoaiTaskBody task={task} loanGuid={loanGuid} />;
    case taskKey === 'gr_assets_voa':
      return <GrVoaTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, ASSETS_REVERIFY_TYPES):
      return <AssetsReverifyTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, VOA_TYPES) || taskMeta?.['task-display-type'] === 'voa':
      return <VoaTaskBody task={task} loanGuid={loanGuid} />;
    case isType(taskType, BORROWER_AFFIRMATION_TYPES):
      return <BorrowerAffirmations task={task} loanGuid={loanGuid} />;
    case taskMeta?.['task-display-type'] === 'upload' || taskActionType === 'upload':
      return <UploadTaskBody task={task} loanGuid={loanGuid} visible={visible} setAccordionActive={setAccordionActive} />;
    default:
      log({
        level: 'error',
        loanGuid,
        taskId,
        message: `Unhandled task ${JSON.stringify({
          taskId,
          source,
          taskType,
          taskCategory,
          taskActionType,
          taskDisplayType: taskMeta?.['task-display-type'],
        })}`,
      });
      return <DefaultTaskBody task={task} loanGuid={loanGuid} />;
  }
};

// -----------------------------------------------------------------------------
// Task Template Collapsible

const TaskCollapsible = ({ task, loanGuid, index }: TaskCollapsibleProps) => {
  const isCompleted = task['task-completed'];
  const isNotCollapsed = !isCompleted && index === 0;
  const [isAccordionActive, setIsAccordionActive] = useState(isNotCollapsed);
  const [accordionActive, setAccordionActive] = useState(isNotCollapsed);

  useEffect(() => {
    setAccordionActive(isAccordionActive);
  }, [isAccordionActive]);

  return (
    <div
      className={cn('task', 'task--collapsable', `${isCompleted ? 'task--complete' : 'task--incomplete'}`)}
      data-task-id={task['task-id']}
    >
      <Accordion
        title={<TaskTitleTemplate task={task} loanGuid={loanGuid} />}
        onToggle={setIsAccordionActive}
        active={accordionActive}
        scrollOnActive={true}
      >
        <div className='task-body pb-6'>
          <TaskBodyTemplate task={task} loanGuid={loanGuid} visible={isAccordionActive} setAccordionActive={setAccordionActive} />
        </div>
      </Accordion>
    </div>
  );
};

// -----------------------------------------------------------------------------
// Task Template Title CTA

const TaskTitleCta = ({ task, loanGuid }: TaskTitleCtaProps) => {
  return (
    <div
      className={cn(
        'task',
        'task--title-cta',
        `${task['task-completed'] ? 'task--complete' : 'task--incomplete'}`,
        'flex items-start justify-between py-6',
      )}
      data-task-id={task['task-id']}
    >
      <div className='task-content pr-6'>
        <TaskTitleTemplate task={task} loanGuid={loanGuid} />
      </div>
      <div className='task-body mr-1'>
        <TaskBodyTemplate task={task} loanGuid={loanGuid} />
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// Task Templates

export const TaskTemplate = ({ task, loanGuid, index }: TaskTemplateProps) => {
  const { 'task-type': taskType } = task;
  switch (true) {
    case isType(taskType, CONSENT_TYPES):
    case isType(taskType, LANGUAGE_PREFERENCE_TYPES):
    case isType(taskType, AGENT_TYPES):
      return <TaskTitleCta task={task} loanGuid={loanGuid} />;
    case isType(taskType, PAYMENT_TYPES):
    default:
      return <TaskCollapsible task={task} loanGuid={loanGuid} index={index} />;
  }
};

const Task = ({ id, loanGuid, index }: TaskProps) => {
  const task = useSelector((state: RootState) => selectors.getTaskById(state, id));
  if (!task) return null;
  return <TaskTemplate task={task} loanGuid={loanGuid} index={index} />;
};

export default Task;
