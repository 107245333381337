import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { PATHS, linkTo } from '../components/routes/paths';
import { AlertType } from '../components/ui/alert/IAlert';
import { isFundedLoan } from '../features/loans/helpers';
import { AlpMilestone, AlpLoanType, Loan, AlpMilestoneState } from '../features/loans/types';
import { alpHELOCApplication } from '../utils/grLinks';
import { capitalize } from '../utils/str';

export const useAlpHELOCLoanConfig = (loan: Loan): LoanConfig => {
  let milestoneText = undefined;
  const loanTypeText = 'HELOC';
  let actionLabel = undefined;
  let actionHref = undefined;
  let alertType: AlertType = 'info';
  let alertText = undefined;
  let alertButtonText = undefined;
  let alertButtonHref = undefined;
  let showTracker = true;
  let trackerValue = 5;

  const { 'loan-guid': loanGuid, 'alp-loan-type': alpLoanType, 'alp-heloc-loan': helocLoan } = loan;

  if (loan && alpLoanType === AlpLoanType.HELOC && helocLoan) {
    milestoneText = helocLoan.milestone === AlpMilestone.DENIED ? undefined : capitalize(helocLoan.milestone);
    const conditionsRequested = helocLoan['conditions-requested'];
    const state = helocLoan.state;

    if (isFundedLoan(loan)) {
      showTracker = false;
      actionHref = linkTo(PATHS.homeLoanServicing, { guid: loanGuid });
      actionLabel = 'Get servicing information';
      milestoneText = 'Closed';
    } else if (helocLoan.milestone === AlpMilestone.CLOSED) {
      actionHref = linkTo(PATHS.homeLoanOverview, { guid: loanGuid });
      trackerValue = 90;
      actionLabel = 'View loan status';
    } else if (helocLoan.milestone === AlpMilestone.CLOSING) {
      actionHref = linkTo(PATHS.homeLoanOverview, { guid: loanGuid });
      trackerValue = 80;
      actionLabel = 'View loan status';
      // Your closing appointment is scheduled!
    } else if (helocLoan.milestone === AlpMilestone.DENIED) {
      actionHref = linkTo(PATHS.homeLoanOverview, { guid: loanGuid });
      trackerValue = 25;
      actionLabel = 'View loan status';
      alertType = 'info';
      alertText = 'After carefully reviewing your application, we are sorry to advise you that we cannot grant you a loan. Your notice will be added to your documents within 30 days.';
      alertButtonText = 'Apply again';
      alertButtonHref = alpHELOCApplication(loanGuid);
    } else if (helocLoan.milestone === AlpMilestone.APPLICATION) {
      trackerValue = 25;
      actionLabel = 'Please complete your application to proceed with your loan.';
      actionHref = alpHELOCApplication(loanGuid);
    } else if (helocLoan.milestone === AlpMilestone.PREAPPROVED || helocLoan.milestone === AlpMilestone.REVIEWED) {
      trackerValue = 50;
      milestoneText = 'Pre-qualified';
      actionLabel = 'Please complete your application to proceed with your loan.';
      actionHref = conditionsRequested ? linkTo(PATHS.homeLoanOverview, { guid: loanGuid }) : alpHELOCApplication(loanGuid);
    } else if (helocLoan.milestone === AlpMilestone.APPROVED) {
      trackerValue = 75;
      actionLabel = 'Please complete your application to proceed with your loan.';
      if (state === AlpMilestoneState.APPROVED_DISCLOSURES) {
        actionLabel = 'Please view and sign your loan disclosures.';
        actionHref = alpHELOCApplication(loanGuid);
      } else if (state === AlpMilestoneState.APPROVED_SCHEDULE) {
        actionLabel = 'Please select your preferred closing date and time.';
        actionHref = alpHELOCApplication(loanGuid);
      } else {
        actionHref = linkTo(PATHS.homeLoanOverview, { guid: loanGuid });
      }
    }
  }

  return {
    milestoneText,
    loanTypeText,
    showTracker,
    trackerValue,
    actionLabel,
    actionHref,
    alertText,
    alertType,
    alertButtonText,
    alertButtonHref,
  };
};
