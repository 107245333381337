export enum DOC_TITLES {
  helocLoan = 'HELOC loan details',
  helocLoanServicing = 'HELOC loan servicing',
  personalLoan = 'Personal loan details',
}

export enum TITLES {
  dashboard = 'Dashboard',
  loanCenter = 'Loan center',
  homeLoanOverview = 'Overview',
  homeLoanTasks = 'Tasks',
  homeLoanDetails = 'Details',
  homeLoanDocuments = 'Documents',
  homeLoanServicing = 'Servicing',
  helocLoan = 'Details',
  helocLoanServicing = 'Servicing',
  personalLoan = 'Details',
  homeValue = 'Home value',
  homeSearch = 'Home Search',
  financialSolutions = 'Financial solutions',
  financialSolution = 'Financial solution',
  profile = 'My profile',
  registration = 'Registration',
  insurance = 'Insurance',
  styleguide = 'Styleguide',
  login = 'Log in',
  logout = 'Log out',
  notFound = 'Not found',
}

export enum NAMES {
  dashboard = 'dashboard',
  loanCenter = 'loanCenter',
  homeLoan = 'homeLoan',
  homeLoanOverview = 'homeLoanOverview',
  homeLoanTasks = 'homeLoanTasks',
  homeLoanDetails = 'homeLoanDetails',
  homeLoanDocuments = 'homeLoanDocuments',
  homeLoanServicing = 'homeLoanServicing',
  helocLoan = 'helocLoan',
  helocLoanServicing = 'helocLoanServicing',
  personalLoan = 'personalLoan',
  homeValue = 'homeValue',
  homeSearch = 'homeSearch',
  financialSolutions = 'financialSolutions',
  financialSolution = 'financialSolution',
  profile = 'profile',
  login = 'login',
  registration = 'registration',
  insurance = 'insurance',
  styleguide = 'styleguide',
  logout = 'logout',
  notFound = 'notFound',
}

export enum PATHS {
  home = '/',
  dashboard = '/dashboard',
  loanCenter = '/loans',
  homeLoan = '/loan/:guid',
  homeLoanOverview = '/loan/:guid/overview',
  homeLoanTasks = '/loan/:guid/tasks',
  homeLoanDetails = '/loan/:guid/details',
  homeLoanDocuments = '/loan/:guid/documents',
  homeLoanServicing = '/loan/:guid/servicing',
  helocLoan = '/loan/heloc/:guid',
  helocLoanServicing = '/loan/heloc/:guid/servicing',
  personalLoan = '/loan/personal/:guid',
  homeValue = '/home-value',
  homeSearch = '/home-search',
  financialSolutions = '/financial-solutions',
  financialSolution = '/financial-solution/:solutionId',
  profile = '/profile',
  login = '/login',
  registration = '/registration',
  insurance = '/insurance',
  styleguide = '/styleguide',
  logout = '/logout',
}

export enum PAGES {
  homeLoanOverview = 'overview',
  homeLoanTasks = 'tasks',
  homeLoanDetails = 'details',
  homeLoanDocuments = 'documents',
  homeLoanServicing = 'servicing',
}

export const homeLoanPaths = [
  PATHS.homeLoan,
  PATHS.homeLoanServicing,
  PATHS.homeLoanOverview,
  PATHS.homeLoanTasks,
  PATHS.homeLoanDetails,
  PATHS.homeLoanDocuments,
];

export const homeLoanInProcessPaths = [
  PATHS.homeLoan,
  PATHS.homeLoanOverview,
  PATHS.homeLoanTasks,
  PATHS.homeLoanDetails,
  PATHS.homeLoanDocuments,
];

export const homeLoanFundedPaths = [PATHS.homeLoan, PATHS.homeLoanServicing, PATHS.homeLoanDocuments];

export const loanGuidPaths = [
  PATHS.homeLoan,
  PATHS.homeLoanOverview,
  PATHS.homeLoanTasks,
  PATHS.homeLoanDetails,
  PATHS.homeLoanDocuments,
  PATHS.homeLoanServicing,
  PATHS.helocLoan,
  PATHS.personalLoan,
];

// Order must match the same as useRoutes
export const breadcrumbPaths = [
  PATHS.personalLoan,
  PATHS.helocLoan,
  PATHS.helocLoanServicing,
  PATHS.homeLoanOverview,
  PATHS.homeLoanTasks,
  PATHS.homeLoanDetails,
  PATHS.homeLoanDocuments,
  PATHS.homeLoanServicing,
  PATHS.insurance,
  PATHS.profile,
  PATHS.homeValue,
  PATHS.styleguide,
];

// path that shows the loan officer support footer - cannot have loan in context
export const loanOfficerSupportFooterPaths = [
  PATHS.financialSolutions,
  PATHS.financialSolution,
  PATHS.loanCenter,
];

export const linkTo = (pathKey: PATHS, params: Record<string, string> = {}): string => {
  return Object.keys(params).reduce(
    (newPath: string, key: string): string => newPath.replace(`:${key}`, params[key] || ''),
    Object.values(PATHS).find((path: string) => path === pathKey) || '',
  );
};

export const pathToPageMap = (pathname: PATHS) => {
  switch (pathname) {
    case PATHS.homeLoanDetails:
      return PAGES.homeLoanDetails;
    case PATHS.homeLoanDocuments:
      return PAGES.homeLoanDocuments;
    case PATHS.homeLoanTasks:
      return PAGES.homeLoanTasks;
    case PATHS.homeLoanServicing:
      return PAGES.homeLoanServicing;
    case PATHS.homeLoanOverview:
    default:
      return PAGES.homeLoanOverview;
  }
};
