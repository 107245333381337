import { selectAppLanguage, selectGlobalAdtrk } from '../../../features/app/appSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { useMultilingual } from '../../../hooks/useMultilingual';
import { dmxApplication, isExternalUrl } from '../../../utils/grLinks';
import Button from './Button';
import cn from 'classnames';
import { iButtonType } from './IButton';
import { selectCurrentLo } from '../../../features/user/selectCurrentLo';
import Modal from '../modal/Modal';
import { useState } from 'react';
import ConfirmationModalContent from '../modal/ConfirmationModalContent';
import { selectProcessingAlpHELOCLoanGuids, selectProcessingCESLoanGuids, selectProcessingLoanGuids } from '../../../features/loans/loansSlice';
import { getDisplayedLoanNumber as getPersonalLoanNumber, selectPersonalLoanById, selectProcessingPersonalLoanIds } from '../../../features/personalLoans/personalLoansSlice';
import { getDisplayedLoanNumber as getHELOCLoanNumber, selectHELOCLoanById, selectProcessingHELOCLoanIds } from '../../../features/helocLoans/helocLoansSlice';
import { useLoan } from '../../../features/loans/hooks';
import { useLoanConfig } from '../../../hooks/useLoanConfig';
import { Loan } from '../../../features/loans/types';
import { linkTo, PATHS } from '../../routes/paths';
import { useAlpHELOCLoanConfig } from '../../../hooks/useAlpHELOCLoanConfig';
import { useHELOCLoanConfig } from '../../../hooks/useHELOCLoanConfig';
import { usePersonalLoanConfig } from '../../../hooks/usePersonalLoanConfig';
import { isFundedLoan } from '../../../features/loans/helpers';

const ApplyLoanButton = (
  {
    id,
    empId,
    onClick,
    className,
    buttonType = 'tertiary',
    text = 'Apply now',
  }:
  {
    id?: string;
    empId?: number;
    onClick: () => void;
    className?: string;
    buttonType?: iButtonType;
    text?: string;
  }
) => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const langPref = multilingual && appLanguage ? appLanguage : undefined;
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const lo = useAppSelector(selectCurrentLo);
  const [showModal, setShowModal] = useState(false);
  const employeeId = empId || lo?.['emp-id'];

  const processinghomeLoanIds = useAppSelector(selectProcessingLoanGuids);
  const processingCESLoanIds = useAppSelector(selectProcessingCESLoanGuids);
  const processingPersonalLoanIds = useAppSelector(selectProcessingPersonalLoanIds);
  const processingAlpHELOCLoanIds = useAppSelector(selectProcessingAlpHELOCLoanGuids);
  const processingHELOCLoanIds = useAppSelector(selectProcessingHELOCLoanIds);

  const totalProcessingLoansCount = processinghomeLoanIds.length + processingCESLoanIds.length + processingHELOCLoanIds.length + processingPersonalLoanIds.length + processingAlpHELOCLoanIds.length;

  const handleClick = () => {
    if (totalProcessingLoansCount > 0) {
      setShowModal(true);
    } else {
      onCreateApplication();
    }
  };

  const onCreateApplication = () => {
    const href = dmxApplication({ 'emp-id': employeeId, langPref, adtrk });
    window.open(href, '_blank', 'noopener');
    onClick?.();
    setShowModal(false);
  };

  const onCancelApplication = () => {
    setShowModal(false);
  };


  const LoanNumberLinkWidget = ({ text, actionHref }: { text: string, actionHref?: string }) => {
    const isLoanActionExternal = actionHref && isExternalUrl(actionHref);
    return (
      <>
        {actionHref ?
          <Button
            buttonType='quaternary'
            text={text}
            href={actionHref}
            {...(!isLoanActionExternal && { to: actionHref })}
            {...(isLoanActionExternal && { href: actionHref, target: '_blank' })}
            onClick={() => setShowModal(false)}
          />
          :
          <span>{text}</span>
        }
      </>
    );
  };

  const ProcessingHomeLoanWidget = ({ loanGuid }: { loanGuid: string }) => {
    const { data } = useLoan(loanGuid);
    const loanConfig = useLoanConfig(data as Loan);

    if (!data || !loanConfig) return null;

    return (
      <div>
        {loanConfig.loanTypeText} #<LoanNumberLinkWidget text={data['loan-number']} actionHref={loanConfig.actionHref} />
      </div>
    );
  };

  const ProcessingCESLoanWidget = ({ loanGuid }: { loanGuid: string }) => {
    const { data } = useLoan(loanGuid);

    if (!data) return null;

    const actionHref = isFundedLoan(data) ?
      linkTo(PATHS.homeLoanServicing, { guid: loanGuid }) :
      linkTo(PATHS.homeLoanOverview, { guid: loanGuid });

    return (
      <div>
        Home Equity #<LoanNumberLinkWidget text={data['loan-number']} actionHref={actionHref} />
      </div>
    );
  };

  const ProcessingAlpHELOCLoanWidget = ({ loanGuid }: { loanGuid: string }) => {
    const { data } = useLoan(loanGuid);
    const loanConfig = useAlpHELOCLoanConfig(data as Loan);

    if (!data || !loanConfig) return null;

    return (
      <div>
        {loanConfig.loanTypeText} #<LoanNumberLinkWidget text={data['loan-number']} actionHref={loanConfig.actionHref} />
      </div>
    );
  };

  const ProcessingHELOCLoanWidget = ({ loanGuid }: { loanGuid: string }) => {
    const loan = useAppSelector(state => selectHELOCLoanById(state, loanGuid));
    const loanConfig = useHELOCLoanConfig(loan);

    if (!loan || !loanConfig) return null;

    return (
      <div>
        {loanConfig.loanTypeText} #<LoanNumberLinkWidget text={getHELOCLoanNumber(loan)} actionHref={loanConfig.actionHref} />
      </div>
    );
  };

  const ProcessingPersonalLoanWidget = ({ loanGuid }: { loanGuid: string }) => {
    const loan = useAppSelector(state => selectPersonalLoanById(state, loanGuid));
    const loanConfig = usePersonalLoanConfig(loan);

    if (!loan || !loanConfig) return null;

    return (
      <div>
        {loanConfig.loanTypeText} #<LoanNumberLinkWidget text={getPersonalLoanNumber(loan)} actionHref={loanConfig.actionHref} />
      </div>
    );
  };

  const ProcessingLoansList = () => {
    return (
      <div className={cn('grid grid-cols-1 gap-2 justify-items-start', { 'md:grid-cols-1': totalProcessingLoansCount < 5, 'md:grid-cols-2': totalProcessingLoansCount >= 5 })}>
        {processinghomeLoanIds.map(loanGuid => {
          return (
            <ProcessingHomeLoanWidget key={loanGuid} loanGuid={loanGuid} />
          );
        })}
        {processingCESLoanIds.map(loanGuid => {
          return (
            <ProcessingCESLoanWidget key={loanGuid} loanGuid={loanGuid} />
          );
        })}
        {processingAlpHELOCLoanIds.map(loanGuid => {
          return (
            <ProcessingAlpHELOCLoanWidget key={loanGuid} loanGuid={loanGuid} />
          );
        })}
        {processingHELOCLoanIds.map(loanGuid => {
          return (
            <ProcessingHELOCLoanWidget key={loanGuid} loanGuid={loanGuid} />
          );
        })}
        {processingPersonalLoanIds.map(loanGuid => {
          return (
            <ProcessingPersonalLoanWidget key={loanGuid} loanGuid={loanGuid} />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={showModal}
        contentLabel='Create a new application'
        onRequestClose={() => setShowModal(false)}
      >
        <ConfirmationModalContent
          title='Create a new application'
          text='It looks like you already have an application or loan in process:'
          confirmButtonText='Create new'
          onConfirm={onCreateApplication}
          cancelButtonText='Nevermind'
          onCancel={onCancelApplication}
          textNode={<ProcessingLoansList />}
        />
      </Modal>
      <Button
        className={cn(className)}
        buttonType={buttonType}
        text={text}
        onClick={handleClick}
        id={id}
      />
    </>
  );
};

export default ApplyLoanButton;
