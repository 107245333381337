import { AlpMilestone, type Loan } from '../../../features/loans/types';
import { selectUser } from '../../../features/user/userSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { usdAmount } from '../../../utils/amount';
import { FORMAT_SHORT, formatDateWithoutTimezone } from '../../../utils/date';
import { alpHELOCApplication } from '../../../utils/grLinks';
import Button from '../../ui/button/Button';

const getLoanAmount = (loanAmount: number | undefined) => {
  return loanAmount ? usdAmount(loanAmount, false, false) : undefined;
};

export const AlpHELOCMilestoneHeadline = ({ loan }: { loan: Loan }) => {
  const {
    'loan-guid': loanGuid,
    'alp-heloc-loan': helocLoan,
    'base-loan-amount': loanAmount,
    'scheduled-closing-date': scheduledClosingDate,
  } = loan;
  const milestone = helocLoan?.milestone;
  const currentUser = useAppSelector(selectUser);
  const userFirstName = currentUser?.name?.first;

  let title = undefined;
  let contentNode = <p className='text-xl'>For your requested amount of {getLoanAmount(loanAmount)}</p>;

  if (milestone === AlpMilestone.APPLICATION) {
    title = `You're almost pre-approved!`;
  } else if (milestone === AlpMilestone.PREAPPROVED) {
    title = `You're pre-qualified!`;
  } else if (milestone === AlpMilestone.REVIEWED) {
    title = `Your file has been reviewed!`;
  } else if (milestone === AlpMilestone.APPROVED) {
    title = `You're approved!`;
  } else if (milestone === AlpMilestone.CLOSING) {
    title = `You're closing!`;
    if (scheduledClosingDate) {
      contentNode = (
        <p className='text-xl'>
          Your closing appointment is scheduled {formatDateWithoutTimezone(scheduledClosingDate, FORMAT_SHORT)}!
        </p>
      );
    } else {
      contentNode = <p className='text-xl'>Your closing appointment is scheduled soon!</p>;
    }
  } else if (milestone === AlpMilestone.CLOSED) {
    title = `Congrats${userFirstName ? `, ${userFirstName}` : ''}!`;
    contentNode = (
      <p className='text-xl'>
        If we can be of any help to you or your family and friends in the future, please do not hesitate to reach out.
      </p>
    );
  } else if (milestone === AlpMilestone.DENIED) {
    title = 'Thank you for your recent application for a Guaranteed Rate Home Equity Loan';
    contentNode = (
      <>
        <p className='text-xl'>
          After carefully reviewing your application, we are sorry to advise you that we cannot grant you a loan. Your notice will
          be added to your documents within 30 days.
        </p>
        <p className='text-xl mt-8'>You can click “Apply again” to start a new application.</p>
        <Button
          className='mt-10'
          buttonType='primary'
          size='large'
          text='Apply again'
          href={alpHELOCApplication(loanGuid)}
          target='_blank'
        />
      </>
    );
  }

  return (
    <>
      <h1 className='text-marketing-xs md:text-marketing-md mb-2'>{title}</h1>
      <>{contentNode}</>
    </>
  );
};
