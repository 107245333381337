import { DashboardAlpHELOCLoanCardPresentational } from '../../../components/loan/loanCard/DashboardAlpHELOCLoanCard';
import { DashboardHELOCLoanCardPresentational } from '../../../components/loan/loanCard/DashboardHELOCLoanCard';
import { DashboardHomeLoanCardPresentational } from '../../../components/loan/loanCard/DashboardHomeLoanCard';
import { DashboardNoLoansCard } from '../../../components/loan/loanCard/DashboardLoanCards';
import { DashboardPersonalLoanCardPresentational } from '../../../components/loan/loanCard/DashboardPersonalLoanCard';
import { AlpMilestone, AlpMilestoneState } from '../../../features/loans/types';
import { useAlpHELOCLoanConfig } from '../../../hooks/useAlpHELOCLoanConfig';
import { getAlpHELOCLoan, getFundedAlpHELOCLoan } from '../../../mocks/alpHelocLoanInprocess';
import helocLoanFunded from '../../../mocks/helocLoanFunded';
import loanFunded from '../../../mocks/loanFunded';
import loanInprocess from '../../../mocks/loanInprocess';
import { personalLoanInprocess } from '../../../mocks/personalLoanInprocess';

const HomeLoanCard1 = () => {
  const loan = loanInprocess;
  return (
    <>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const HomeLoanCard2 = () => {
  const loan = loanFunded;
  loan['lead-source'] = 'rdc';
  return (
    <>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const VaHomeLoanCard1 = () => {
  const loan = { ...loanInprocess };
  loan['va?'] = true;
  return (
    <>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard2 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.PREAPPROVED);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard3 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.REVIEWED);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCardApproved = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPROVED);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCardApprovedDisclosures = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPROVED, AlpMilestoneState.APPROVED_DISCLOSURES);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};
const HELOCLoanCardApprovedSchedule = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPROVED, AlpMilestoneState.APPROVED_SCHEDULE);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard5 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.DENIED);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard6 = () => {
  const loan = getFundedAlpHELOCLoan();
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard7 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.CLOSED);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCardClosing = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.CLOSING);
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <>
      <small>
        <p>
          {loan['alp-heloc-loan']?.milestone} - {loan['alp-heloc-loan']?.state} -&gt; {loanConfig.actionLabel}
        </p>
        <p>{loanConfig.actionHref} </p>
      </small>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard8 = () => {
  const loan = helocLoanFunded;
  return (
    <>
      <DashboardHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const PersonalLoanCard = () => {
  const loan = personalLoanInprocess;
  return (
    <>
      <DashboardPersonalLoanCardPresentational loan={loan} />
    </>
  );
};

const DashboardLoanCard = () => {
  const loan = loanInprocess;
  return <DashboardHomeLoanCardPresentational loan={loan} />;
};

const DashboardHELOCLoanCard = () => {
  const loan = helocLoanFunded;
  return <DashboardHELOCLoanCardPresentational loan={loan} />;
};

const DashboardALPHELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  return <DashboardAlpHELOCLoanCardPresentational loan={loan} />;
};

const DashboardPersonalLoanCard = () => {
  const loan = personalLoanInprocess;
  return <DashboardPersonalLoanCardPresentational loan={loan} />;
};

const LoanCardsStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Mock home loan cards</div>
      <div className='mb-2' />
      <HomeLoanCard1 />
      <div className='mb-2' />
      <HomeLoanCard2 />
      <div className='mb-2' />
      <VaHomeLoanCard1 />
      <div className='header-medium my-8'>Mock falcon loan cards</div>
      <div className='mb-2' />
      <HELOCLoanCard1 />
      <div className='mb-2' />
      <HELOCLoanCard2 />
      <div className='mb-2' />
      <HELOCLoanCard3 />
      <div className='mb-2' />
      <HELOCLoanCardApprovedDisclosures />
      <div className='mb-2' />
      <HELOCLoanCardApprovedSchedule />
      <div className='mb-2' />
      <HELOCLoanCardApproved />
      <div className='mb-2' />
      <HELOCLoanCardClosing />
      <div className='mb-2' />
      <HELOCLoanCard7 />
      <div className='mb-2' />
      <HELOCLoanCard5 />
      <div className='mb-2' />
      <HELOCLoanCard6 />
      <div className='header-medium my-8'>HELOC loan cards</div>
      <HELOCLoanCard8 />
      <div className='header-medium my-8'>Personal loan cards</div>
      <PersonalLoanCard />
      <div className='header-medium my-8'>Mock Dashboard loan cards</div>
      <div className='mb-2' />
      <DashboardNoLoansCard />
      <div className='mb-2' />
      <DashboardLoanCard />
      <div className='mb-2' />
      <DashboardHELOCLoanCard />
      <div className='mb-2' />
      <DashboardALPHELOCLoanCard1 />
      <div className='mb-2' />
      <DashboardPersonalLoanCard />
    </>
  );
};

export default LoanCardsStyleguide;
