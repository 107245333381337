import { ReactNode, useRef } from 'react';
import { DashboardMilestoneBadge } from '../milestone/MilestoneComposite';
import Avatar from '../../ui/avatar/Avatar';
import ApplyLoanButton from '../../ui/button/ApplyLoanButton';
import Button from '../../ui/button/Button';
import Card from '../../ui/card/Card';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { LoanOfficer, LoanTeam } from '../../../features/loans/types';
import { log } from '../../../utils/logger';
import cn from 'classnames';
import { LoanConfig } from './ILoanCard';
import IAddress from '../../../interfaces/IAddress';
import { isExternalUrl } from '../../../utils/grLinks';
import { LoanCardAlert } from './LoanCard';
import Address from '../../ui/address/Address';
import { SocialShareConfigs } from '../../social/ISocialShareConfig';
import SocialShareComponent from '../../social/SocialShareComponent';
import VaLoanImage from '../../../images/va-loans.svg';
import RdcLoanImage from '../../../images/rdc-hta-logo.png';
import { isEmpty } from '../../../utils/object';

export const DashboardNoLoansCard = () => {
  const onApplyClick = () => {
    sendEvent('startAnApplication: dashboard no loan card');
    log({ level: 'info', message: 'startAnApplication: dashboard no loan card' });
  };

  return (
    <Card className='bg-white' dropShadow={true}>
      <div className='flex flex-col lg:flex-row items-baseline lg:items-center'>
        <div className='flex flex-col flex-grow'>
          <p className='text-xl font-bold'>No loans in progress</p>
          <p className='mt-2'>You currently have no loans in progress. You can begin the process by applying for a loan.</p>
        </div>
        <ApplyLoanButton className='mt-4 lg:mt-0 w-full lg:w-fit-content whitespace-nowrap h-fit-content' onClick={onApplyClick} buttonType='primary' />
      </div>
    </Card>
  );
};

export const DashboardLoanCard = (
  {
    loanConfig,
    team,
    address,
    dateNode,
    loanNumber,
    configKey,
  }:
  {
    loanConfig: LoanConfig,
    team?: LoanTeam,
    loanAmount: number,
    address?: IAddress,
    dateNode?: ReactNode,
    loanNumber: string,
    configKey?: SocialShareConfigs,
  }
) => {
  const anchorRef = useRef(null);
  const isLoanActionExternal = !!(loanConfig.actionHref && isExternalUrl(loanConfig.actionHref));

  const onCardClick = () => {
    const link = anchorRef?.current as HTMLButtonElement | null;
    if (link?.click) {
      link.click();
    }
  };

  const LoanOfficerRow = ({ loanOfficer, className }: { loanOfficer: LoanOfficer, className?: string }) => {
    return (
      <div className={cn(className, 'flex flex-row items-center')}>
        <Avatar
          size='small'
          photo={loanOfficer['photo-url']}
          alt={`Photo of ${loanOfficer.name}`}
          data-qa='person-photo'
        />
        <p className='ml-2'>Working with {loanOfficer.name}</p>
      </div>
    );
  };

  return (
    <Card
      anchorRef={anchorRef}
      dropShadow={true}
      className='bg-white'
      onClick={loanConfig.actionLabel ? onCardClick : undefined}
      {...(loanConfig.actionLabel && { title: loanConfig.actionLabel })}
    >
      <div className='flex flex-row'>
        <div className='flex flex-col flex-grow'>
          {/* milestone and badges */}
          {(loanConfig.milestoneText || loanConfig.isVA || loanConfig.isRDC) && <div className='mb-2 flex flex-row items-center gap-x-2'>
            {loanConfig.milestoneText && <DashboardMilestoneBadge text={loanConfig.milestoneText} />}
            {(loanConfig.isVA && !loanConfig.isRDC) && <img src={VaLoanImage} alt={`VA Loan`} width='44' height='36' />}
            {loanConfig.isRDC && <img src={RdcLoanImage} alt={`Realtor.com Loan`} width='36' height='36' />}
          </div>}
          {/* address */}
          {address && !isEmpty(address) && <Address {...address} singleLine={true} />}

          {/* line break */}
          {(loanConfig.milestoneText || loanConfig.isVA || loanConfig.isRDC || (address && !isEmpty(address))) && <div className='mt-2' />}

          {/* some kind of date */}
          {dateNode && <div className='text-xs'>{dateNode}</div>}
          {/* loan number */}
          <p className='text-xs'>{loanConfig.loanTypeText} {loanNumber}</p>
          {/* support */}
          {team?.lo && <LoanOfficerRow className='mt-2' loanOfficer={team?.lo} />}
          {/* share news */}
          {configKey && <SocialShareComponent className='mt-2' configKey={configKey} />}
        </div>
        {loanConfig.actionHref && loanConfig.actionLabel && (
          <div
            className={`flex justify-end h-full ${loanConfig.showTracker ? 'col-span-2 md:col-span-1' : 'col-span-1'}`}
            style={{ height: 'min-content', position: 'relative', bottom: '8px' }}
          >
            <Button
              anchorRef={anchorRef}
              buttonType='icon'
              text={loanConfig.actionLabel}
              title={loanConfig.actionLabel}
              iconSize='1.25rem'
              iconName='chevron-right-large'
              iconHoverEffect={true}
              {...(!isLoanActionExternal && { to: loanConfig.actionHref })}
              {...(isLoanActionExternal && { href: loanConfig.actionHref, target: '_blank' })}
            />
          </div>
        )}
      </div>
      {loanConfig.alertType && loanConfig.alertText && (
        <LoanCardAlert
          type={loanConfig.alertType}
          text={loanConfig.alertText}
          buttonHref={loanConfig.alertButtonHref}
          buttonText={loanConfig.alertButtonText}
        />
      )}
    </Card>
  );
};
