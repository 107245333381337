import { Task } from '../../../features/tasks/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { selectLoanVoaVendor } from '../../../features/loans/loansSlice';
import { selectGatelessVoaSsoLinkId } from '../../../features/gateless/selectors';
import { useEffect, useRef, useState } from 'react';
import Modal from '../../ui/modal/Modal';
import { Connect, ConnectErrorEvent } from 'connect-web-sdk';
import { log } from '../../../utils/logger';
import { setGatelessVoaSsoLinkId } from '../../../features/gateless/actions';
import Button from '../../ui/button/Button';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import { serializeError } from '../../../utils/serializeError';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import { closeModal, openModal } from '../tasklist/TaskModal';
import api from '../../../features/api';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';
import VideoModal from '../../ui/videoModal/VideoModal';

export const VOA_TYPES = ['voa', 'assets_vendor_voa'] as const;

export const VoaAdditionalDescription = ({ loanGuid, taskId }: { loanGuid: string, taskId: string }) => {
  const videoLink = 'https://player.vimeo.com/progressive_redirect/playback/1064807447/rendition/1080p/file.mp4?loc=external&signature=f993bf847609d740deba54a76f9846d6107da5897e43cba6e327cd849cf6394c&user_id=179927723';

  const handleVideoClick = () => {
    log({ loanGuid, taskId, level: 'info', message: `VOA task ${taskId}: video presented to user` });
  };

  return (
    <VideoModal
      videoLink={videoLink}
      onVideoLinkClick={handleVideoClick}
      promptText='Verifying your information digitally can help complete your loan faster, for more information on asset verification'
      linkText='click here.'
      title='Asset Verification Video'
    />
  );
};

const VoaTaskBody = ({ loanGuid, task }: { loanGuid: string, task: Task<'voa' | 'assets_vendor_voa'> }) => {
  const dispatch = useAppDispatch();
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const appLanguage = useAppSelector(selectAppLanguage) || DEFAULT_LANGUAGE;
  const voaVendor = useAppSelector(state => selectLoanVoaVendor(state, loanGuid));
  const isFinicity = voaVendor === 'finicity';
  const voaLinkId = useAppSelector(state => selectGatelessVoaSsoLinkId(state, loanGuid));
  const { 'task-id': taskId, 'task-completed': taskCompleted, source, 'task-type': taskType } = task;
  const [voaUrl, setVoaUrl] = useState<string>();
  const [showVoaModal, setShowVoaModal] = useState(false);
  const [voaModalOpened, setVoaModalOpened] = useState(false);
  const [showRemoveVoaModal, setShowRemoveVoaModal] = useState(false);
  const [showRemoveVoaFailed, setShowRemoveVoaFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeTaskLoading, setRemoveTaskLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const waitingLinkTimeoutRef = useRef<number>();
  const defaultSupport = useDefaultSupport();

  useEffect(() => {
    return () => {
      waitingLinkTimeoutRef.current && window.clearTimeout(waitingLinkTimeoutRef.current);
      Connect?.destroy();
    };
  }, []);

  useEffect(() => {
    // if VOA link id is truthy - do not do anything
    if (!voaLinkId) {
      return;
    }

    // if not waiting for link ID
    if (!waitingLinkTimeoutRef.current) {
      log({ loanGuid, taskId, level: 'error', message: `VOA: received unexpected VOA link ID update ${voaLinkId}` });
      return;
    }

    try {
      log({ loanGuid, taskId, level: 'info', message: `VOA: Get link Id ${voaLinkId}` });

      window.clearTimeout(waitingLinkTimeoutRef.current);
      waitingLinkTimeoutRef.current = undefined;

      if (!voaLinkId || !voaVendor) {
        handleError();
        return;
      }

      api.getVoaSsoLink(loanGuid, voaVendor, voaLinkId).then(resp => {
        const ssoLink = resp.data.ssoLink;
        log({ loanGuid, taskId, level: 'info', message: `VOA: Got link ${ssoLink}` });
        setVoaUrl(ssoLink);
        setLoading(false);
        setShowVoaModal(true);
      });
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `VOA: Get link failed ${serializeError(error)}` });
      handleError();
    }
  }, [voaLinkId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!voaUrl) return;

    // if finicity
    if (isFinicity && voaModalOpened) {
      handleFinicity(voaUrl);
    }

    // else render the URL in an iframe
  }, [voaUrl, voaModalOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const onVoaModalOpened = () => {
    setVoaModalOpened(true);
  };

  const onVoaModalClosed = () => {
    setVoaModalOpened(false);
  };

  const handleError = () => {
    setLoading(false);
    setError('Asset verify error. Please try again later.');
  };

  const handleClose = () => {
    Connect?.destroy();
    // clean out sso link id - start fresh every click
    dispatch(setGatelessVoaSsoLinkId(loanGuid, undefined));
    setVoaUrl(undefined);
    setShowVoaModal(false);
  };

  const handleFinicity = (voaUrl: string) => {
    Connect.launch(voaUrl,
      {
        onDone: () => {
          handleClose();
        },
        onCancel: () => {
          handleClose();
        },
        onError: (event: ConnectErrorEvent) => {
          log({ loanGuid, taskId, level: 'error', message: `VOA: Finicity error ${event}` });
        }
      },
      {
        selector: '#finicity-connect-container',
        overlay: 'rgba(255, 255, 255, 0)',
      }
    );
  };

  const handleVoaClick = async () => {
    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: 'VOA: Order' });
      setLoading(true);
      setError(undefined);

      if (!voaVendor) {
        throw new Error('VOA: Missing VOA Vendor');
      } else if (!Connect) {
        throw new Error('Finicity Connect not available in VOA');
      }

      await api.orderVoaReport(loanGuid, voaVendor, appLanguage);

      // timeout with an error if the gateless event with link ID do not come back (EVENT_VOA_SSO_LINK_AVAILABLE)
      waitingLinkTimeoutRef.current = window.setTimeout(() => {
        log({ loanGuid, taskId, level: 'error', message: `VOA: Link ID timed out` });
        waitingLinkTimeoutRef.current = undefined;
        handleError();
      }, 30000); // timeout in 30 seconds
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `VOA: Order failed ${serializeError(error)}` });
      handleError();
    }
  };

  const handleRemoveVoaTask = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOA: Verify assets manually' });
    setShowRemoveVoaModal(true);
  };

  const VoaTask = () => {
    return (
      <div className='flex flex-col justify-center'>
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          disabled={disabled}
          onClick={handleVoaClick}
        >
          {taskCompleted ? 'Add/review accounts' : 'Verify assets automatically'}
        </FormButtonComponent>
        <div className='text-center'>
          <Button
            buttonType='tertiary'
            text='or, verify your assets manually'
            disabled={disabled || loading}
            onClick={handleRemoveVoaTask}
          />
        </div>
      </div>
    );
  };

  const onVoaModalClose = () => {
    handleClose();
  };

  const onRemoveTask = async () => {
    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: 'VOA: Confirm verify assets manually' });
      setDisabled(true);
      setRemoveTaskLoading(true);
      await api.patchLoanTask(loanGuid, taskId, { source, 'task-removed': true });
      log({ loanGuid, taskId, level: 'info', message: 'VOA: Confirm verify assets manually succeed' });
      // hide component's modal and open a task modal - this will stay open after the tasks update
      setShowRemoveVoaModal(false);
      openModal(
        taskId,
        <ConfirmationModalContent
          title='You opted out of asset verification.'
          textNode={<p>Please upload documents to tasks manually.</p>}
          cancelButtonText='Back to your tasklist'
          onCancel={() => closeModal(taskId)}
        />,
        'Opted out asset verification'
      );
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `VOA: Confirm verify assets manually failed ${serializeError(error)}` });
      setDisabled(false);
      setShowRemoveVoaFailed(true);
    } finally {
      setRemoveTaskLoading(false);
    }
  };

  const onRemoveModalClose = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOA: Close verify assets manually' });
    setShowRemoveVoaModal(false);
    setShowRemoveVoaFailed(false);
  };

  return (
    <>
      <Modal
        open={showVoaModal}
        contentLabel='VOA'
        onRequestClose={onVoaModalClose}
        fullWidth={true}
        onOpened={onVoaModalOpened}
        onClosed={onVoaModalClosed}
        contentStyle={isFinicity ? { width: '100%', height: '755px' } : undefined}
      >
        {isFinicity
          ? <div id='finicity-connect-container' className='flex place-items-center justify-center' />
          : <div className='flex place-items-center justify-center'>
              <iframe title='Verify Assets' id='voa-connect-container' src={voaUrl} style={{ width: '500px', height: '755px' }} />
            </div>
        }
      </Modal>
      <Modal open={showRemoveVoaModal} contentLabel='VOA' onRequestClose={onRemoveModalClose} fullWidth={true}>
        {
          showRemoveVoaFailed ?
            <ErrorModalContent
              title='Unable to opt out of asset verification.'
              location='your tasklist'
              support={defaultSupport}
              onDone={onRemoveModalClose}
            />
          :
            <ConfirmationModalContent
              title='Are you sure you want to provide documents manually?'
              textNode={
                <div className='flex flex-col'>
                  <p className='mb-4'>We will add multiple tasks to your tasklist asking for documents. This may be less convenient than providing verification via automated account access.</p>
                  <p className='font-bold'>This cannot be undone.</p>
                </div>
              }
              confirmButtonText='Yes, continue'
              onConfirm={onRemoveTask}
              confirmLoading={removeTaskLoading}
              cancelButtonText='No, cancel'
              onCancel={onRemoveModalClose}
              className="px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto"
            />
        }
      </Modal>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      <VoaAdditionalDescription loanGuid={loanGuid} taskId={task['task-id']} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <VoaTask />
    </>
  );
};

export default VoaTaskBody;
