import { useState } from 'react';
import Modal from '../modal/Modal';
import Embed from '../embed/Embed';
import Button from '../button/Button';

interface Props {
  videoLink: string;
  onVideoLinkClick: () => void;
  promptText: string;
  linkText: string;
  title: string;
}

const VideoModal = (props: Props) => {
  const { videoLink, onVideoLinkClick, promptText, linkText, title } = props;
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleVideoClick = () => {
    onVideoLinkClick();
    setShowVideoModal(true);
  };

  const handleVideoClose = () => {
    setShowVideoModal(false);
  };

  return (
    <>
      <p>
        {promptText} <Button buttonType='quaternary' onClick={handleVideoClick}>{linkText}</Button>
      </p>
      <Modal
        open={showVideoModal}
        contentLabel={title}
        onRequestClose={handleVideoClose}
        fullWidth={true}
      >
        <div className='flex items-center justify-center'>
          <Embed
            className='block md:hidden'
            title={title}
            src={videoLink}
            width='100%'
            // in mobile - screen with * 0.56 as 16:9 aspect ratio
            style={{ height: 'calc(100vw * 0.56)' }}
          />
          <Embed
            className='hidden md:block'
            title={title}
            src={videoLink}
            width='675'
            height='380'
          />
        </div>
      </Modal>
    </>
  );
};

export default VideoModal;
