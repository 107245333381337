import { ReactNode } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { SubpageNavigation } from '../../components/navigation/SubpageNavigation';
import { PATHS, linkTo } from '../../components/routes/paths';
import { TextBadgeProps } from '../../components/ui/badge/ITextBadge';
import { NumberBadge } from '../../components/ui/badge/NumberBadge';
import TextBadge from '../../components/ui/badge/TextBadge';
import { selectHasNewDocument, useFetchDocuments } from '../../features/documents/documentsSlice';
import { isFundedLoan, isLoanReady, willNeverHaveDocuments } from '../../features/loans/helpers';
import { AlpLoanType, type Loan } from '../../features/loans/types';
import { selectHasNewPreapproval, useFetchPreapprovals } from '../../features/preapprovals/preapprovalsSlice';
import { selectors as tasksSelectors } from '../../features/tasks';
import { useAppSelector } from '../../hooks/hooks';
import { useFetchTasks } from '../../hooks/useFetchTasks';
import { RootState } from '../../store/types';

export const buildTab = (
  text: string,
  path: PATHS,
  currentPath: string,
  loanGuid?: string,
  disabled?: boolean,
  primaryBadge?: TextBadgeProps | ReactNode,
  secondaryBadge?: TextBadgeProps | ReactNode,
  isActive?: () => boolean,
) => {
  const params = loanGuid ? { guid: loanGuid } : undefined;
  return {
    text,
    value: text,
    to: linkTo(path, params),
    primaryBadge,
    secondaryBadge,
    isActive: currentPath === path || isActive?.(),
    disabled,
  };
};

export const buildLoanTabs = (
  pathname: string,
  loanGuid: string,
  incompleteTasksCount: number | undefined,
  isFunded: boolean,
  isLoanReady: boolean,
  isAlpHELOC: boolean,
  disableDocuments?: boolean,
  hasNewDocuments?: boolean,
) => {
  const path = loanGuid ? pathname.replace(loanGuid, ':guid') : pathname;
  const tabs = [];

  // In-process tasklist Loan
  if (!isFunded) {
    tabs.push(buildTab('Overview', PATHS.homeLoanOverview, path, loanGuid));

    const disableTasksTab = !isLoanReady || (isAlpHELOC && !incompleteTasksCount);
    tabs.push(
      buildTab(
        'Tasks',
        PATHS.homeLoanTasks,
        path,
        loanGuid,
        disableTasksTab,
        isLoanReady && incompleteTasksCount && incompleteTasksCount > 0 ? (
          <NumberBadge number={incompleteTasksCount} bgColor='tertiaryBtn-text' textColor='white' />
        ) : undefined,
        isLoanReady && incompleteTasksCount && incompleteTasksCount > 0 ? (
          <NumberBadge number={incompleteTasksCount} bgColor='tertiaryBtn-text' textColor='white' />
        ) : undefined,
      ),
    );

    tabs.push(buildTab('Loan details', PATHS.homeLoanDetails, path, loanGuid, !isLoanReady));
  }

  // Funded servicing Loan
  if (isFunded) {
    tabs.push(buildTab('Loan details', PATHS.homeLoanServicing, path, loanGuid));
  }

  // All loans, check if documents should be shown
  if (!disableDocuments) {
    tabs.push(
      buildTab(
        'Documents',
        PATHS.homeLoanDocuments,
        path,
        loanGuid,
        !isFunded && !isLoanReady,
        !isFunded && isLoanReady && hasNewDocuments && <TextBadge className='ml-2' type='navigation' text='New' />,
        !isFunded && isLoanReady && hasNewDocuments && <TextBadge className='ml-2' type='navigation' text='New' />,
      ),
    );
  }

  return tabs;
};

const LoanNav = ({ loan }: { loan: Loan }) => {
  const { pathname } = useLocation();
  const documentsRouteMatch = useRouteMatch({ path: PATHS.homeLoanDocuments, exact: true });
  const { 'loan-guid': loanGuid, 'alp-loan-type': alpLoanType } = loan;
  const isFunded = loan && isFundedLoan(loan);
  const idsByGroups = useAppSelector((state: RootState) => tasksSelectors.getLoanTaskIdsByGroups(state, loanGuid));

  useFetchTasks(loanGuid);
  useFetchPreapprovals(loanGuid);
  useFetchDocuments(loanGuid, isFunded);

  const hasNewPreapproval = useAppSelector(state => selectHasNewPreapproval(state, loanGuid));
  const hasNewDocument = useAppSelector(state => selectHasNewDocument(state, loanGuid));
  const showNewBadge = !documentsRouteMatch && (hasNewPreapproval || hasNewDocument);

  const tabs = buildLoanTabs(
    pathname,
    loanGuid,
    idsByGroups?.incomplete.length,
    isFundedLoan(loan),
    isLoanReady(loan),
    alpLoanType === AlpLoanType.HELOC,
    willNeverHaveDocuments(loan),
    showNewBadge,
  );

  return <SubpageNavigation tabs={tabs} />;
};

export default LoanNav;
