import { LoanOfficerCardModalContent } from '../modals/support/LoanOfficerCardModalContent';
import { ActionBannerProps } from '../../ui/actionBanner/iActionBanner';
import { AlpLoanType, AlpMilestone, Loan, LoanOfficer } from '../../../features/loans/types';
import { LoanMilestones } from '../../../features/loans/types';
import ActionBannerList from '../../ui/actionBannerList/ActionBannerList';
import { linkTo, PATHS } from '../../routes/paths';
import { alpHELOCApplication, talkuments } from '../../../utils/grLinks';
import { GenericUploadButton } from '../../tasks/upload/GenericUploadAlert';
import { ActionBannerSkeleton } from '../../ui/actionBanner/ActionBanner';
import { useAppSelector } from '../../../hooks/hooks';
import { selectors as taskSelectors } from '../../../features/tasks';
import { useFetchTasks } from '../../../hooks/useFetchTasks';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { isLoanTasksReady, isLoanReady } from '../../../features/loans/helpers';
import { CustomerCareCardModalContent } from '../modals/support/CustomerCareCardModalContent';
import { content } from '../../../features/tenant/tenant';
import useGenericUploads from '../../../hooks/useGenericUploads';
import { selectHasClosingDocument, useFetchDocuments } from '../../../features/documents/documentsSlice';
import { log } from '../../../utils/logger';

export type ErrorType = 'tasksFailed' | 'loanNotReady';

export type NextStepsProps = {
  loanGuid: string;
  loanNumber: string;
  milestone?: LoanMilestones;
  alpMilestone?: AlpMilestone;
  loanOfficer?: LoanOfficer;
  isLoanReady?: boolean;
  isTasksReady?: boolean;
  incompleteTasksCount?: number;
  tasksFailed?: boolean;
  tasksHasData?: boolean;
  isTalkumentsEnabled?: boolean;
  language?: string;
  hasClosingDocument?: boolean;
  isAlpHELOC: boolean;
};

export const loanAssistance = (errorType: ErrorType, loanOfficer?: LoanOfficer): ActionBannerProps => {
  const contact = !loanOfficer ? 'support' : 'your loan officer';
  const { supportEmail, supportPhone, supportPhoneOption } = content;
  let title, description;

  switch (errorType) {
    case 'loanNotReady':
      title = 'Complete the set up of your loan';
      description = `Reach out to ${contact} to complete the set up of your loan`;
      break;
    case 'tasksFailed':
      title = 'Your tasks are not loading';
      description = `Reach out to ${contact} for assistance`;
      break;
  }

  return {
    priority: 0,
    bannerIcon: 'warning-triangle',
    title: title,
    description: description,
    contentLabel: 'loanAssistance',
    buttonProps: {
      text: `Contact ${contact}`,
    },
    modalContent: !loanOfficer ? (
      <CustomerCareCardModalContent email={supportEmail} phone={supportPhone} phoneOption={supportPhoneOption} />
    ) : (
      <LoanOfficerCardModalContent loanOfficer={loanOfficer} />
    ),
  };
};

export const offerAccepted = (loanOfficer: LoanOfficer): ActionBannerProps => {
  return {
    priority: 1,
    bannerIcon: 'home',
    title: 'Offer accepted?',
    description: 'Connect with your loan officer and upload your purchase contract',
    contentLabel: 'offerAccepted',
    buttonProps: {
      text: 'Get Started',
    },
    modalContent: <LoanOfficerCardModalContent loanOfficer={loanOfficer} />,
  };
};

export const managePreapprovals = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 2,
    bannerIcon: 'file',
    title: 'Download your pre-approval letter',
    description: 'Now you can begin making offers today',
    contentLabel: 'managePreapprovals',
    buttonProps: {
      text: 'Download your letter',
      to: linkTo(PATHS.homeLoanDocuments, { guid: loanGuid }),
    },
  };
};

export const alpApplication = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 2,
    bannerIcon: 'file',
    title: 'Complete your application to select your offer',
    description: `You're almost pre-approved!`,
    contentLabel: 'alpApplication',
    buttonProps: {
      text: 'Continue',
      href: alpHELOCApplication(loanGuid),
    },
  };
};

export const alpPreapproved = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 2,
    bannerIcon: 'file',
    title: 'Please complete your application to proceed with your loan.',
    description: `You're pre-approved!`,
    contentLabel: 'alpPreapproved',
    buttonProps: {
      text: 'Continue',
      href: alpHELOCApplication(loanGuid),
    },
  };
};

export const alpReviewed = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 2,
    bannerIcon: 'file',
    title: 'Please complete your application to select your offer',
    description: 'Your file has been reviewed!',
    contentLabel: 'alpReviewed',
    buttonProps: {
      text: 'Continue',
      href: alpHELOCApplication(loanGuid),
    },
  };
};

export const openTasks = (loanGuid: string, incompleteTasksCount: number): ActionBannerProps => {
  return {
    priority: 3,
    bannerNumber: incompleteTasksCount,
    title: 'Complete your remaining tasks',
    description: `You have ${incompleteTasksCount} task${incompleteTasksCount > 1 ? 's' : ''} to complete`,
    contentLabel: 'openTasks',
    buttonProps: {
      text: 'Complete tasks',
      to: linkTo(PATHS.homeLoanTasks, { guid: loanGuid }),
    },
  };
};

export const visitTalkuments = (loanGuid: string, loanNumber: string, language: string): ActionBannerProps => {
  return {
    priority: 4,
    bannerIcon: 'doc-long',
    title: 'Want to view your documents in Spanish?',
    description: 'View loan disclosures and other helpful documents in Spanish',
    contentLabel: 'visitTalkuments',
    buttonProps: {
      text: 'Visit Talkuments',
      href: `${talkuments()}?ln=${loanNumber}${language ? `&l=${language}` : ''}`,
      onClick: () => log({ loanGuid, level: 'info', message: 'Talkuments: User clicked `Visit Talkuments` action banner button' }),
    },
  };
};

export const genericUpload = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 5,
    bannerIcon: 'money-bag',
    title: 'You are all caught up! There are no tasks to complete at the moment.',
    description: (
      <>
        If your Loan Officer requested any documents, you can upload them{' '}
        <GenericUploadButton loanGuid={loanGuid} buttonProps={{ text: 'here' }} source='LoanNextSteps' backToText='Back to overview' />.
      </>
    ),
    contentLabel: 'genericUpload',
  };
};

export const sitTight = (): ActionBannerProps => {
  return {
    priority:5,
    bannerIcon: 'money-bag',
    title: 'You are all caught up! There are no tasks to complete at the moment.',
    description: 'There are no tasks to complete at the moment.',
    contentLabel: 'sitTight',
  };
};

export const closingDocument = (loanGuid: string): ActionBannerProps => {
  return {
    priority: 6,
    bannerIcon: 'file',
    title: 'View your closing package before signing!',
    description: 'Click here to view today',
    contentLabel: 'closingDocument',
    buttonProps: {
      text: 'View closing document',
      to: linkTo(PATHS.homeLoanDocuments, { guid: loanGuid }),
    },
  };
};

export const LoanNextStepsList = ({
  milestone,
  alpMilestone,
  loanOfficer,
  loanGuid,
  loanNumber,
  hasClosingDocument,
  isLoanReady,
  isTasksReady,
  incompleteTasksCount,
  isTalkumentsEnabled,
  tasksFailed,
  tasksHasData,
  language,
  isAlpHELOC,
}: NextStepsProps) => {
  const nextSteps: ActionBannerProps[] = [];
  const genericUploadsEnabled = useGenericUploads(loanGuid);

  if (milestone === LoanMilestones.CLOSED || alpMilestone === AlpMilestone.CLOSED) {
    return (
      <>
        <h2 className='header-small mt-8 mb-2'>What to do next</h2>
        <p>
          Sit tight and check back in 3 - 5 days for servicing information. Also, be on the lookout for a mailed letter from where
          you'll be making your monthly payments.
        </p>
      </>
    );
  }

  if (!isLoanReady) {
    nextSteps.push(loanAssistance('loanNotReady', loanOfficer));
  } else {
    if (tasksFailed && !tasksHasData) {
      nextSteps.push(loanAssistance('tasksFailed', loanOfficer));
    }

    if (tasksHasData && !!incompleteTasksCount) {
      nextSteps.push(openTasks(loanGuid, incompleteTasksCount));
    }

    if ((tasksHasData && incompleteTasksCount === 0 && !isAlpHELOC) && genericUploadsEnabled) {
      nextSteps.push(genericUpload(loanGuid));
    }

    if (alpMilestone && alpMilestone === AlpMilestone.APPLICATION) {
      nextSteps.push(alpApplication(loanGuid));
    }

    if (alpMilestone && alpMilestone === AlpMilestone.REVIEWED) {
      nextSteps.push(alpReviewed(loanGuid));
    }

    if (alpMilestone && alpMilestone === AlpMilestone.PREAPPROVED) {
      nextSteps.push(alpPreapproved(loanGuid));
    }

    if (alpMilestone && (alpMilestone === AlpMilestone.APPROVED || alpMilestone === AlpMilestone.CLOSING)) {
      nextSteps.push(sitTight());
    }
    
    if (milestone === LoanMilestones.PREAPPROVED) {
      nextSteps.push(managePreapprovals(loanGuid));
    }

    if (isTalkumentsEnabled && language) {
      nextSteps.push(visitTalkuments(loanGuid, loanNumber, language));
    }

    if (
      milestone &&
      [LoanMilestones.APPLICATION, LoanMilestones.PREAPPROVAL, LoanMilestones.PREAPPROVED].includes(milestone) &&
      loanOfficer
    ) {
      nextSteps.push(offerAccepted(loanOfficer));
    }

    if (hasClosingDocument) {
      nextSteps.push(closingDocument(loanGuid));
    }
  }

  return (
    <>
      {nextSteps.length > 0 && <h2 className='header-small mt-8 mb-6'>What to do next</h2>}
      <ActionBannerList actions={nextSteps} />
      {isLoanReady && isTasksReady && !tasksHasData && !tasksFailed && <ActionBannerSkeleton />}
    </>
  );
};

export const LoanNextSteps = ({ loan }: { loan: Loan }) => {
  const {
    'loan-guid': loanGuid,
    'loan-number': loanNumber,
    'loan-milestone': milestone,
    team,
    'alp-loan-type': alpLoanType,
    'alp-heloc-loan': alpHELOCLoan,
    'show-talkuments-link?': isTalkumentsEnabled,
  } = loan;
  const { incomplete } = useAppSelector(state => taskSelectors.getLoanTaskIdsByGroups(state, loanGuid));
  const incompleteTasksCount = incomplete.length;
  const { hasTasksData, hasTasksError } = useFetchTasks(loanGuid, { refetchOnError: true });
  const { hasData: hasDocumentsData } = useFetchDocuments(loanGuid);
  const language = useAppSelector(selectAppLanguage);
  const isReady = isLoanReady(loan);
  const isTasksReady = isLoanTasksReady(loan);
  const hasClosingDocument = useAppSelector(state => hasDocumentsData && selectHasClosingDocument(state, loanGuid));

  return (
    <LoanNextStepsList
      loanGuid={loanGuid}
      loanNumber={loanNumber}
      loanOfficer={team?.lo}
      milestone={milestone}
      alpMilestone={alpHELOCLoan?.milestone}
      isLoanReady={isReady}
      isTasksReady={isTasksReady}
      incompleteTasksCount={incompleteTasksCount}
      tasksHasData={hasTasksData}
      tasksFailed={hasTasksError}
      hasClosingDocument={hasClosingDocument}
      {...(language && { language })}
      isTalkumentsEnabled={isTalkumentsEnabled}
      isAlpHELOC={alpLoanType === AlpLoanType.HELOC}
    />
  );
};
