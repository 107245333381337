import { useState } from 'react';
import { isPreapprovalsEnabled } from '../../../features/loans/helpers';
import { Loan, LoanMilestones } from '../../../features/loans/types';
import VaLoanImage from '../../../images/va-loans.svg';
import RdcLoanImage from '../../../images/rdc-hta-logo.png';
import Button from '../../ui/button/Button';
import Modal from '../../ui/modal/Modal';
import HorizontalStepComponent from '../../ui/stepper/HorizontalStepComponent';
import { CurrentStepIcon } from '../../ui/stepper/StepWidgets';
import VerticalStepComponent from '../../ui/stepper/VerticalStepComponent';
import { LOAN_STATUS_MAP } from '../loanStatusInfo/LoanStatus';

export const MilestoneTracker = ({
  isPreapprovalsEnabled,
  isVA,
  isRDC,
  milestone,
  trackerMilestone,
}: {
  isPreapprovalsEnabled: boolean;
  isVA: boolean;
  isRDC: boolean;
  milestone: LoanMilestones;
  trackerMilestone?: string | null;
}) => {
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const mobileMilestoneTitle = trackerMilestone || LOAN_STATUS_MAP[milestone].mobileMilestoneTitle;
  trackerMilestone = trackerMilestone || LOAN_STATUS_MAP[milestone].trackerMilestone;

  const milestones = [
    {
      id: LoanMilestones.CONDITIONALLY_APPROVED,
      name: LOAN_STATUS_MAP[LoanMilestones.CONDITIONALLY_APPROVED].trackerTitle as string,
      description: LOAN_STATUS_MAP[LoanMilestones.CONDITIONALLY_APPROVED].trackerDescription,
    },
    {
      id: LoanMilestones.CLEAR_TO_CLOSE,
      name: LOAN_STATUS_MAP[LoanMilestones.CLEAR_TO_CLOSE].trackerTitle as string,
      description: LOAN_STATUS_MAP[LoanMilestones.CLEAR_TO_CLOSE].trackerDescription,
    },
    {
      id: LoanMilestones.CLOSED,
      name: LOAN_STATUS_MAP[LoanMilestones.CLOSED].trackerTitle as string,
      description: LOAN_STATUS_MAP[LoanMilestones.CLOSED].trackerDescription,
    },
  ];

  if (isPreapprovalsEnabled) {
    milestones.unshift({
      id: LoanMilestones.PREAPPROVED,
      name: LOAN_STATUS_MAP[LoanMilestones.PREAPPROVED].trackerTitle as string,
      description: LOAN_STATUS_MAP[LoanMilestones.PREAPPROVED].description,
    });
  }

  const onTrackerInfoClick = () => {
    setShowTrackerModal(true);
  };

  const InfoButton = () => {
    return (
      <Button
        buttonType='icon'
        iconName='information-circle'
        iconSize='1.5rem'
        text='Expand tracker'
        className='ml-4 text-action-100'
        onClick={onTrackerInfoClick}
      />
    );
  };

  return (
    <div className='px-4 md:px-6 lg:px-8 py-6 border border-gray-25 rounded-xl'>
      <div className='flex'>
        <div className='hidden sm:flex flex-grow'>
          {(isVA || isRDC) && (
            <div className='flex flex-shrink-0 pr-4 md:pr-6 mr-4 md:mr-6 border-r border-gray-25 self-center'>
              <img src={isRDC ? RdcLoanImage : VaLoanImage} alt={isRDC ? 'Realtor.com Loan' : 'VA Loan'} width='46' />
            </div>
          )}
          <HorizontalStepComponent width='110px' stepList={milestones} currentStepId={trackerMilestone} />
          <InfoButton />
        </div>
        <div className='flex w-full sm:hidden items-center'>
          {(isVA || isRDC) && (
            <>
              <div className='flex flex-shrink-0 self-center h-8'>
                <div className='flex items-center'>
                  <img src={isRDC ? RdcLoanImage : VaLoanImage} alt={isRDC ? 'Realtor.com Loan' : 'VA Loan'} width='32' />
                </div>
              </div>
              <div className='mx-4 border-l border-gray-25' style={{ height: 'calc(100% + 2rem)' }} />
            </>
          )}
          <CurrentStepIcon />
          <p className='font-bold flex-grow text-sm mx-2'>Milestone: {mobileMilestoneTitle}</p>
          <InfoButton />
        </div>
      </div>
      <Modal open={showTrackerModal} contentLabel='milestone tracker' onRequestClose={() => setShowTrackerModal(false)}>
        <p className='text-marketing-xs md:text-marketing-md mb-4'>
          The typical <span className='whitespace-nowrap'>mortgage process</span>
        </p>
        <VerticalStepComponent stepList={milestones} currentStepId={trackerMilestone} />
      </Modal>
    </div>
  );
};

export const LoanMilestoneTracker = ({ loan }: { loan: Loan }) => {
  const {
    'conditional-approval-with-letter?': hasConditionalApprovalAndLetter,
    'va?': isVA,
    'loan-milestone': milestone,
    'refinance?': isRefi,
    'lead-source': leadSource,
  } = loan;
  const isRDC = leadSource === 'rdc';
  const preapprovalsEnabled = isPreapprovalsEnabled(loan);
  let trackerMilestone;
  if (hasConditionalApprovalAndLetter) {
    trackerMilestone = LOAN_STATUS_MAP[LoanMilestones.PREAPPROVED].trackerMilestone;
  }

  return (
    <MilestoneTracker
      isPreapprovalsEnabled={!isRefi && preapprovalsEnabled}
      isVA={isVA}
      isRDC={isRDC}
      milestone={milestone}
      trackerMilestone={trackerMilestone}
    />
  );
};

export default LoanMilestoneTracker;
