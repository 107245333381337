import { Task } from '../features/tasks/types';
import nextId from '../utils/nextId';

export const taskEConsent: Task<'eConsent'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'eConsent',
  'task-title': 'EConsent Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': {
    'consent-type': 'eConsent',
    agreed: false,
    consented: false,
    'task-consent-html': 'TEST CONSENT HTML',
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [],
};

export const taskTwnConsent: Task<'twnConsent'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'twnConsent',
  'task-title': 'TWN Task Consent Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': {
    'consent-type': 'twnConsent',
    agreed: false,
    consented: false,
    'task-consent-html': 'TEST CONSENT HTML',
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskAgentConsent: Task<'borrower-agent'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'borrower-agent',
  'task-title': 'Agent Consent Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': {
    email: 'test@yopmail.com',
    'first-name': 'Chun',
    'last-name': 'Lui',
    agree: true,
    'no-agent': false,
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [],
};

export const taskLangPref: Task<'language_preference'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'language_preference',
  'task-title': 'Language Preference Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': {
    'language-preference': 'en',
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskPayment: Task<'payment_fee'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'payment_fee',
  'task-title': 'Payment Fee Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    'swiper-id': 'TEST SWIPER ID',
    total: {
      amount: 100,
      description: 'TEST TOTAL DESC',
    },
    fees: [
      {
        amount: 100,
        description: 'TEST FEE',
      }
    ],
  },
  'custom-task-meta': {
    paid: false,
    swiper: {
      id: 'TEST SWIPER ID',
      items: [
        {
          desc: 'Application Fee',
          amount: 150,
        },
        {
          desc: 'Test Fee',
          amount: 175,
        },
        {
          desc: 'Test swiper item 1',
          amount: 100,
        },
        {
          desc: 'Test swiper item 2',
          amount: 200,
        },
      ],
      paid: false,
      'pay-url': 'google.com',
      'total-amount': 300,
    }
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskPaymentNoPayment: Task<'payment_fee'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'payment_fee',
  'task-title': 'Payment Fee Task Title (No Payment)',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    'swiper-id': 'TEST SWIPER ID',
    total: {
      amount: 100,
      description: 'TEST TOTAL DESC',
    },
    fees: [
      {
        amount: 100,
        description: 'TEST FEE',
      }
    ],
  },
  'custom-task-meta': {
    paid: false,
    swiper: {
      id: '',
      items: [],
      paid: false,
      'pay-url': 'google.com',
      'total-amount': 100,
    }
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskDataLox: Task<'data_lox'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'data_lox',
  'task-title': 'Data LOX Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskCreditLox: Task<'credit_inquiry_lox'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'credit_inquiry_lox',
  'task-title': 'Credit Inquiry Task LOX Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': {
    'disclaimer-text': {
      en: '<p>TEST disclaimer text</p>',
    },
  },
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [
    {
      additionalData: {
        creditor: 'Foo Bank',
        inquiryDate: '09/01/2023',
      },
      taskExplanationId: 'test_exp_id',
      taskId: 'test_task_id',
    }
  ],
  'affirmation-responses': []
};

export const taskDocutech: Task<'document_signing'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'document_signing',
  'task-title': 'Doctech Task Title',
  'task-description': 'Signing these documents allows us to continue processing your loan.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskDocutechPending: Task<'document_signing'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': true,
  'task-type': 'document_signing',
  'task-title': 'Doctech Task Title (Pending)',
  'task-description': 'Signing these documents allows us to continue processing your loan.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoa: Task<'voa'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'voa',
  'task-title': 'VOA Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoai: Task<'gr_assets_voai'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'gr_assets_voai',
  'task-title': 'VOAI Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVendorVoa: Task<'assets_vendor_voa'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'assets_vendor_voa',
  'task-title': 'Assets Vendor VOA Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoie: Task<'credentialed_voie'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'credentialed_voie',
  'task-title': 'VOIE Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    sessionToken: 'TEST token',
    publishableKey: 'TEST key',
    orderId: 'TEST order id',
  },
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoieReverify: Task<'credentialed_voie_reverify'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'credentialed_voie_reverify',
  'task-title': 'VOIE Reverify Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    sessionToken: 'TEST token',
    publishableKey: 'TEST key',
  },
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskAffirmations: Task<'affirmations'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'affirmations',
  'task-title': 'Affirmations Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [
    {
      affirmationResponseId: 'test_id_1',
      affirmationResponseType: 'Reconcile',
      title: 'TEST TITLE 1',
      content: {
        en: 'TEST CONTENT 1',
      },
      displayOrder: 1,
    },
    {
      affirmationResponseId: 'test_id_2',
      affirmationResponseType: 'Reconcile',
      title: 'TEST TITLE 2',
      content: {
        en: 'TEST CONTENT 2',
      },
      displayOrder: 1,
    },
  ]
};

export const taskInterpretatoin: Task<'interpretation_ack'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'interpretation_ack',
  'task-title': 'Interpretation Ack Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [
    {
      affirmationResponseId: 'test_id_1',
      affirmationResponseType: 'Acknowledge',
      title: 'TEST TITLE 1',
      content: {
        en: 'TEST CONTENT 1',
      },
      displayOrder: 1,
    },
    {
      affirmationResponseId: 'test_id_2',
      affirmationResponseType: 'Acknowledge',
      title: 'TEST TITLE 2',
      content: {
        en: 'TEST CONTENT 2',
      },
      displayOrder: 1,
    },
  ]
};

export const taskUpload: Task<any> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'upload',
  'task-title': 'Upload Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    'task-display-type': 'upload',
  },
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'upload',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskUploadInsurance: Task<any> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'upload',
  'task-title': 'Upload Insurance Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    'task-display-type': 'upload',
  },
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'upload',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [],
  'task-key': 'insurance_quote',
};

export const taskUploadCompleted: Task<any> = {
  'task-id': nextId('test_task_id'),
  'task-completed': true,
  'task-type': 'upload',
  'task-title': 'Upload Task Title (Has Uploaded Docs)',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': {
    'task-display-type': 'upload',
  },
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'upload',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': [],
  documents: [
    {
      href: 'https://google.com',
      id: 'test_doc_id',
      title: 'TEST DOC TITLE 1',
    },
    {
      href: 'https://google.com',
      id: 'test_doc_id_2',
      title: 'TEST DOC TITLE 2',
    },
  ],
};

export const taskVoaiReverify: Task<'gr_voai_reverify'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'gr_voai_reverify',
  'task-title': 'GR VOAI Reverify Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoaReverify: Task<'gr_voa_reverify'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'gr_voa_reverify',
  'task-title': 'GR VOA Reverify Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};

export const taskVoeReverify: Task<'gr_voe_transactions_reverify'> = {
  'task-id': nextId('test_task_id'),
  'task-completed': false,
  'task-type': 'gr_voe_transactions_reverify',
  'task-title': 'GR VOE Reverify Task Title',
  'task-description': 'Lorem ipsum dolor sit amet, tritani labores an sit, at mea posse dolorum, et magna dicunt accumsan mea. Te error tamquam definiebas sed, est ex summo propriae. Id nisl electram pro, est mutat abhorreant cu. Vel cu nihil dolore copiosae.',
  'task-meta': [] as never,
  'custom-task-meta': [] as never,
  'created-on': '',
  'updated-on': '',
  'task-action-type': 'sign',
  source: 'internal',
  'task-explanations': [],
  'affirmation-responses': []
};
